import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import VendingMachineMap from './vending_machine_map';
import CustomConfirmBtn from '../customConfirmBtn/custom_confirm_btn';
import VendingMachineItem from './vending_machine_item';
import { VendingMachineService } from '../../api/v1/api_connect';
import { useClient } from '../../connect_api';
import { VendingMachineInfo } from '../../api/v1/api_pb';
import { Helmet } from 'react-helmet';
import TextBtn from '../textButton/text_button';

export default function VendingMachinePage() {
    const [selectedVendingMachine, setSelectedVendingMachine] = useState<VendingMachineInfo | null>(null);
    const [currentScrollIndex, setCurrentScrollIndex] = useState(0);
    const [vendingMachineInformations, setVendingMachineInformations] = useState<VendingMachineInfo[]>([]);
    const client = useClient(VendingMachineService);
    const navigate = useNavigate();
    const listRef = useRef<HTMLDivElement>(null);
    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

    const getAllVendingMachine = useCallback(async () => {
        try {
            const response = await client.listVendingMachines({});
            setVendingMachineInformations(response.vendingMachines);
        } catch (error) {
            navigate(`/`, { replace: true });
        }
    }, [client, navigate]);

    useEffect(() => {
        getAllVendingMachine();
    }, [getAllVendingMachine]);

    useEffect(() => {
        if (vendingMachineInformations.length > 0) {
            setSelectedVendingMachine(vendingMachineInformations[0]);
        }
    }, [vendingMachineInformations]);

    const handleScroll = useCallback(() => {
        let closestIndex = 0;
        let closestDistance = Number.MAX_VALUE;

        itemRefs.current.forEach((ref, index) => {
            if (ref) {
                const { left, right } = ref.getBoundingClientRect();
                const itemCenter = (left + right) / 2;
                const distance = Math.abs(itemCenter - window.innerWidth / 2);

                if (distance < closestDistance) {
                    closestDistance = distance;
                    closestIndex = index;
                }
            }
        });
        if (closestIndex !== currentScrollIndex) {
            setCurrentScrollIndex(closestIndex);
            setSelectedVendingMachine(vendingMachineInformations[closestIndex]);
        }
    }, [currentScrollIndex, vendingMachineInformations]);

    useEffect(() => {
        const currentListRef = listRef.current;
        if (currentListRef) {
            currentListRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentListRef) {
                currentListRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    const toggleSelectVendingMachine = (vendingMachine: VendingMachineInfo) => {
        const index = vendingMachineInformations.findIndex(
            (vendingMachineInfo) => vendingMachineInfo.address === vendingMachine.address
        );
        if (index !== -1) {
            setCurrentScrollIndex(index);
            setSelectedVendingMachine(vendingMachineInformations[index]);
            if (listRef.current && itemRefs.current[index]) {
                const itemRect = itemRefs.current[index]!.getBoundingClientRect();
                const containerRect = listRef.current.getBoundingClientRect();
                const scrollLeft = listRef.current.scrollLeft + (itemRect.left - containerRect.left);
                listRef.current.scrollTo({
                    left: scrollLeft,
                    behavior: 'smooth'
                });
            }
        } else {
            setSelectedVendingMachine(null);
            setCurrentScrollIndex(0);
            if (listRef.current) {
                listRef.current.scrollTo({
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
    };

    const canNavigate = vendingMachineInformations.length > 0 && selectedVendingMachine !== null;

    return (
        <div>
            <Helmet>
                <title>FindMachine Page</title>
            </Helmet>
            <div className="cusContainer">
                <VendingMachineMap onSelectVendingMachine={toggleSelectVendingMachine} vendingMachines={vendingMachineInformations} selectedVendingMachine={selectedVendingMachine}></VendingMachineMap>
                <div className='vendingMachinPage'>
                    <span className='vendingMachinPageSpan'>Choose the vending machine location you would like to collect your free HIV self-test kits from. </span>
                    <div className="vendingMachineItemList" ref={listRef}>
                        {vendingMachineInformations.map((vendingMachine, index) => (
                            <VendingMachineItem
                                key={vendingMachine.address + 'index'}
                                ref={(el) => (itemRefs.current[index] = el)}
                                onSelectVendingMachine={toggleSelectVendingMachine}
                                isSelected={currentScrollIndex === index}
                                number={index + 1}
                                vendingMachine={vendingMachine}
                            />
                        ))}
                    </div>
                    <div style={{ margin: "0 20px" }}>
                        <CustomConfirmBtn confirmText={selectedVendingMachine?.name ?? ''} confirmAction={() => {
                            if (canNavigate) {
                                navigate('/machine-detail/' + selectedVendingMachine?.machineCode);
                            }
                        }} />
                        <TextBtn textButtonTitle='Go Back' textBtnFunction={() => {
                            navigate('/');
                        }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

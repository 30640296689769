import React, { forwardRef } from 'react'
import vendingMachinePhoto from '../../assets/machine.png'
import { VendingMachineInfo } from '../../api/v1/api_pb';

interface Props {
    number: number;
    vendingMachine: VendingMachineInfo;
    isSelected: boolean;
    onSelectVendingMachine: Function;
}

const VendingMachineItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <div className="machineItem-container" ref={ref} onClick={() => {
            props.onSelectVendingMachine(props.vendingMachine)
        }}>
            <div className={props.isSelected ? "machineItem-topleftSelectedBg" : "machineItem-topleftNormalBg"}>
                {props.number}
            </div>
            <div className="machineItem-size">
                <img src={vendingMachinePhoto} alt="" className='machineItem-image' />
                <div className=''>
                    <div className='machineItem-column'>
                        <p className='machineItem-detail'>{props.vendingMachine.name}</p>
                    </div>
                    <p className='machineItem-title'>{props.vendingMachine.address}</p>
                    <div className='machineItem-column'>
                        <p className='machineItem-detail'>Machine location: {props.vendingMachine.locationName}</p>
                    </div>
                    <div className='machineItem-column'>
                        <p className='machineItem-detail'>Machine hours: {props.vendingMachine.openingHours}</p>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default VendingMachineItem;
